import { GameCardProps } from '../../props';
import styles from '../styles.module.scss';
import skeleton from './styles.module.scss';

export default function GameCardV2Skeleton({
  showTotalPaid,
  largerMobileCardSize,
}: Pick<GameCardProps, 'showTotalPaid' | 'largerMobileCardSize'>) {
  return (
    <div
      className={`${styles.container} ${largerMobileCardSize ? styles['container--larger-mobile-card-size'] : ''}`}
    >
      <div className={styles.container__image__wrapper}>
        <div className={skeleton.image} />
      </div>

      <div className={styles.container__content}>
        <div className={styles.container__content__row}>
          <div className={skeleton.name} />

          <div className={styles['container__hide-mobile']}>
            <div className={skeleton.icon} />
          </div>
        </div>

        <div className={styles['container__hide-mobile']}>
          <div className={skeleton.text} />
        </div>

        {showTotalPaid && (
          <div className={styles.container__content__amount}>
            <div className={skeleton.coin} />
            <div className={skeleton.name} />
          </div>
        )}
      </div>
    </div>
  );
}
